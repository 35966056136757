import { useTranslation } from 'next-i18next'
import React from 'react'

import cs from 'classnames'

type ActivityTypes = {
  text?: string
  className?: string
}

const ActivityIndicator = ({
  text,
  className,
}: ActivityTypes): React.ReactElement => {
  const { t } = useTranslation('common')

  return (
    <div className={cs('activity-indicator', 'fs-unmask', className)}>
      {text ? text : t('ui.activity_indicator')}
    </div>
  )
}

export default ActivityIndicator
