export enum ReferenceType {
  PubMed = 'pubmed',
  PubMedExtract = 'extract',
  General = 'general',
}

export interface IGeneralReference {
  id: string
  title: string
  url?: string
  authors: string
  sourceName: string
  sourceUrl?: string
  publicationYear: number
  publicationMonth?: string
  publicationDay?: number
  type: ReferenceType
}

export interface IPubmedArticle {
  id: string
  date: string
  journal: string
  authors: string[]
  title: string
  abstract: { label?: string; text: string }[]
  type?: ReferenceType
}

// Enums for various MCQ settings
export enum McqSettingsFeedback {
  immediate = 'immediate',
  on_completion = 'on_completion',
  none = 'none',
}

export enum McqSettingsRetry {
  Yes = 'yes',
  No = 'no',
  ReadOnly = 'readonly',
}

export enum McqSettingsLabels {
  numeric = 'numeric',
  alphabetic = 'alphabetic',
}

export enum McqMarkPer {
  question = 'question',
  answer = 'answer',
}

export enum TemplateType {
  SBA = 'Single Best Answer',
  TRUE_FALSE = 'True/False',
  MULTI_SELECT = 'Multiple Correct Answers',
}
