import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import FormattedDate from '../../../../components/CMS/FormattedDate'
import {
  getModuleURLWithRevision,
  LearningSeriesType,
  LearningSessionScore,
  LearningModuleWithSessionType,
} from '../../../../graphql/learning'
import { isNativeAppBrowser } from '../../../../lib/client-info'
import { SubscriptionCardIcon } from '../../../../ui/cards/SubscriptionCardIcon'
import { getDashboardURL } from '../../../../url'
import { SubscribeButton } from '../../../Subscriptions/cart/SubscribeButton'
import { getActiveSub, getSubType } from '../../../Subscriptions/getActiveSub'
import { SubscriptionType } from '../../../Subscriptions/types'
import DownloadCertificateLink from '../../learning-module/certificate/DownloadCertificateLink'

interface Props {
  commonRowData: any
  module: LearningModuleWithSessionType
  series: LearningSeriesType
  lastCompletedSession?: LearningSessionScore
  isRated: boolean
  isReflected: boolean
  /* This is used for when you are browsing a list of your own modules, and you are *not* interested in
  completing modules, rather only to edit (e.g. on /authored) */
  showGotoDashboardButton: boolean
  returnToSeries?: boolean
  isFeed?: boolean
  isPreview?: boolean
}

const ModuleRowCard = ({
  commonRowData,
  module,
  lastCompletedSession,
  isRated,
  isReflected,
  showGotoDashboardButton,
  returnToSeries,
  series,
  isFeed,
  isPreview,
}: Props) => {
  const router = useRouter()

  const { revision } = module

  const {
    payments_enabled: subscriptionBased,
    subscription_settings: subscriptionSettings,
  } = series
  const activeSub = getActiveSub(series, module)
  const subType = getSubType(subscriptionBased, subscriptionSettings)

  let callsToAction
  if (showGotoDashboardButton && !isNativeAppBrowser()) {
    callsToAction = (
      <Link
        href={getDashboardURL(
          `/groups/${module.series.library_id}/learning/series/${module.series.id}/${revision.module_id}/edit`
        )}
        legacyBehavior
      >
        <button
          className="btn btn--fill btn--block"
          style={{ marginBottom: 10, width: '100%' }}
        >
          Edit
        </button>
      </Link>
    )
  } else {
    callsToAction = (
      <>
        {commonRowData.buttons.map(button => (
          <div key={button.toString()}>{button}</div>
        ))}
      </>
    )
  }

  const title = revision?.title.length ? revision.title : 'Untitled'
  const author = revision?.cover_author ?? module?.author?.name
  const accreditor = series?.cme_accreditor ?? revision?.cme_accreditor

  const linkTo = isPreview
    ? null
    : `${getModuleURLWithRevision(series, revision)}${
        returnToSeries ? '?via=series' : ''
      }`

  return (
    <div
      className={
        isFeed
          ? 'fs-unmask'
          : 'fs-unmask layout__item u-1-of-4 u-1-of-2-palm-landscape u-1-of-1-palm-portrait u-1-of-3-lap u-1-of-4-desk u-1-of-6-large-desk'
      }
    >
      <div
        className="card learning-card__wrapper"
        style={isFeed ? { marginLeft: 0 } : null}
      >
        <div className="learning-card__resource" style={{ width: '100%' }}>
          <Link href={linkTo}>
            <div
              style={{
                backgroundImage: `url(${commonRowData.module.coverImage} )`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                height: '100%',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                cursor: 'pointer',
              }}
            >
              <span className="learning-card__label">Module</span>
              {accreditor ? (
                <span
                  className="learning-card__label"
                  style={{ top: '3.2rem', backgroundColor: '#58368a' }}
                >
                  {accreditor}
                </span>
              ) : null}

              <SubscriptionCardIcon
                series={series}
                module={module}
                cardType="module"
              />
            </div>
          </Link>
        </div>

        <div className="learning-card__title-section">
          <div className="learning-card__title">
            <Link href={linkTo}>
              <span title={title}>
                {title.length < 67
                  ? title
                  : title.substring(0, 67).trim() + '...'}
              </span>
            </Link>
          </div>
        </div>

        <div>
          {/* Author */}
          <div className="learning-card__row">
            <Link href={linkTo}>
              <span title={revision?.cover_author ?? module?.author?.name}>
                <strong>Author:</strong>{' '}
                {author?.length < 20
                  ? author
                  : author?.substring(0, 17).trim() + '...'}
              </span>
            </Link>
          </div>

          {/* Series Title*/}
          <div className="learning-card__row" style={{ height: '35px' }}>
            <Link href={linkTo}>
              <span className="" title={series.title}>
                <strong>Series:</strong>{' '}
                <span>
                  {series.title.length < 50
                    ? series.title
                    : series.title.substring(0, 45).trim() + '...'}
                </span>
              </span>
            </Link>
          </div>

          {/* Group title */}
          <div className="learning-card__row" style={{ height: '35px' }}>
            <Link href={linkTo}>
              {series.library?.title && (
                <span className="" title={series.library.title}>
                  <strong>Group:</strong>{' '}
                  {series.library.title.length < 50
                    ? series.library.title
                    : series.library.title.substring(0, 45).trim() + '...'}
                </span>
              )}
            </Link>
          </div>

          {/* Subscription Details */}
          <div
            className="learning-card__row"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'rgba(0, 0, 0, 0.4)',
              marginTop: '12px',
              borderTop: '1px solid rgba(0, 0, 0, 0.05)',
              paddingTop: '5px',
            }}
          >
            <LearningCardFooter
              isSubscription={subscriptionBased}
              activeSub={activeSub}
              lastCompletedSession={lastCompletedSession}
              series={series}
              module={module}
              revision={revision}
              isReflected={isReflected}
              isRated={isRated}
            />
          </div>
        </div>
        <div className="learning-card__buttons">
          {module.needs_subscription && (
            <div>
              <SubscribeButton
                productID={
                  subType === SubscriptionType.MODULE
                    ? module.id
                    : module.series_id
                }
                type={subType}
                availableAsSeries={subscriptionSettings?.available_as_series}
                seriesID={module.series_id}
                libraryID={series.library_id}
                label={
                  subscriptionSettings?.subscription_interval
                    ? 'Subscribe'
                    : 'Buy now'
                }
              />
            </div>
          )}
          {callsToAction}
        </div>
      </div>
    </div>
  )
}

const LearningCardFooter = ({
  isSubscription,
  activeSub,
  lastCompletedSession,
  series,
  module,
  revision,
  isRated,
  isReflected,
}) => {
  if (isSubscription && activeSub && !lastCompletedSession?.finished_at) {
    return (
      <>
        <div>
          <div style={{ fontWeight: 700 }}>Subscribed:</div>
          {activeSub?.created_at ? (
            <FormattedDate date={activeSub.created_at} format="short" />
          ) : (
            'N/A'
          )}
        </div>
        <div style={{ textAlign: 'right' }}>
          <div style={{ fontWeight: 700 }}>Expires:</div>
          {activeSub?.expires_at && activeSub.expires_at < '9999-01-01' ? (
            <FormattedDate date={activeSub.expires_at} format="short" />
          ) : (
            'N/A'
          )}
        </div>
      </>
    )
  } else if (lastCompletedSession?.finished_at) {
    return (
      <>
        <div>
          <div style={{ fontWeight: 700 }}>Completed:</div>
          <FormattedDate
            date={lastCompletedSession.finished_at}
            format="short"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'right',
          }}
        >
          {!isRated && (
            <Link
              href={{
                pathname:
                  getModuleURLWithRevision(series, revision) +
                  '/reflect-and-rate',
                query: {
                  routingGotoFeedback: isReflected,
                  routingSkipStarRating: true,
                },
              }}
              style={{ textDecoration: 'underline' }}
            >
              Add Feedback
            </Link>
          )}
          {isReflected && isRated && (
            <Link
              href={{
                pathname:
                  getModuleURLWithRevision(series, revision) +
                  '/reflect-and-rate',
                query: {
                  routingSkipStarRating: true,
                },
              }}
              style={{
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
                display: 'inline-block',
              }}
            >
              My Reflections
            </Link>
          )}
          <DownloadCertificateLink
            series={series}
            session={lastCompletedSession}
            revision={revision}
          />
        </div>
      </>
    )
  } else {
    return (
      <>
        <div>
          <div style={{ fontWeight: 700 }}>Views:</div>
          {module?.sessions?.views ?? 0}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'right',
          }}
        >
          {revision?.expected_completion_time_minutes && (
            <div>
              <div style={{ fontWeight: 700 }}>Expected Time:</div>
              <TimeTaken
                expectedTime={revision?.expected_completion_time_minutes}
              />
            </div>
          )}
        </div>
      </>
    )
  }
}

const TimeTaken = ({ expectedTime }) => {
  const { t } = useTranslation()

  const hours = Math.floor(expectedTime / 60)

  const hoursString =
    hours >= 1 &&
    t('learning:learning.cover_page_time_taken_hours', { count: hours })

  const mins = expectedTime % 60

  const minString =
    mins > 0 &&
    t('learning:learning.cover_page_time_taken_mins', { count: mins })

  return (
    <>
      {hoursString} {minString}
    </>
  )
}

export default ModuleRowCard
