import { ApolloError, useQuery } from '@apollo/client'
import { GetFormDocument } from '../../graphql/operations'
import { FormType } from './types'

interface FormTypeQueryResult {
  form?: FormType
  error: ApolloError | undefined
  loading: boolean
}

const useForm = (formID: string): FormTypeQueryResult => {
  if (!formID) return

  const { loading, error, data } = useQuery(GetFormDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { id: formID },
  })

  if (data?.form) {
    const form: FormType = {
      id: data.form.id,
      creatorID: data.form.creator_id,
      name: data.form.name,
      description: data.form.description,
      fields: JSON.parse(data.form.fields),
      isFeedbackForm: data.form.is_feedback_form,
      allowMultipleSubmissions: data.form.allow_multiple_submissions,
      groupId: data.form.group_id,
    }

    return {
      loading,
      error,
      form,
    }
  }

  return { loading, error }
}

export default useForm
