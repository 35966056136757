import cs from 'classnames'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React, { useMemo } from 'react'

import {
  getModuleURLWithRevision,
  LearningModuleRevisionType,
  LearningModuleWithSessionType,
  LearningSeriesType,
  SeriesURLParts,
} from '../../../../graphql/learning'
import { generateUUID } from '../../../../lib/uuid'
import { selectAuthenticatedUser } from '../../../../modules/auth/selectors'
import { useSelector } from '../../../../react-redux'
import { LinkPropsType } from '../../../../ui/link'
import { getImageURL } from '../../../../url'
import { McqSettingsRetry } from '../../blocks/types'
import { PLACEHOLDER_IMG_SRC } from './SeriesRow'
import { CurrentUserModuleStatus } from './types'

type ButtonLinks = {
  start: LinkPropsType
  results: LinkPropsType
  reflectAndRate: LinkPropsType
  readOnly: LinkPropsType
}

export const generateLinks = (
  series: SeriesURLParts,
  revision: LearningModuleRevisionType
): ButtonLinks => {
  const url = getModuleURLWithRevision(series, revision)

  const start = { href: url }
  const results = { href: `${url}/results` }
  const reflectAndRate = { href: `${url}/reflect-and-rate` }
  const readOnly = { href: `${url}/read-only` }

  return { start, results, reflectAndRate, readOnly }
}

export const generateLabels = (): Record<string, string> => {
  return {
    start: 'group.learning_cta_start',
    continue: 'group.learning_cta_continue',
    retry: 'group.learning_cta_retry',
    results: 'group.learning_cta_view_results',
    reflectAndRate: 'group.learning_cta_reflect_and_rate',
    view: 'group.learning_cta_view_read_only',
    details: 'group.learning_cta_view_details',
  }
}

const generatePrimaryButton = (
  linkProps: LinkPropsType,
  labelId: string,
  generateBtnClasses: (label: string) => string,
  t: (key: string) => string
): JSX.Element => (
  <Link key={0} {...linkProps} legacyBehavior>
    <button className={generateBtnClasses(labelId)} id={labelId}>
      {t(labelId)}
    </button>
  </Link>
)

export const getCommonRowData = (
  revision: LearningModuleRevisionType,
  buttons: JSX.Element[]
) => {
  const getCoverImage = () => {
    const thumbnailPath = revision.card_thumbnail?.path
    const coverImagePath = revision.cover_image?.path

    if (thumbnailPath) {
      return getImageURL('small', 'cms', encodeURI(thumbnailPath))
    }

    if (coverImagePath) {
      return getImageURL('small', 'cms', encodeURI(coverImagePath))
    }

    return PLACEHOLDER_IMG_SRC
  }

  const getAuthorInfo = () => `${revision?.cover_author ?? ''}`

  return {
    module: {
      coverImage: getCoverImage(),
      authorInfo: getAuthorInfo,
    },
    buttons,
  }
}

interface LinkAndButtonLabelProps {
  series: LearningSeriesType
  module: LearningModuleWithSessionType
  revision: LearningModuleRevisionType
  everPublished: boolean
  currentUserModuleStatus: CurrentUserModuleStatus
  previousModulesCompleted: boolean
  isPreview?: boolean
}

// generateLinkAndButtonLabel creates the primary button for module cards based on module and user session state
export const generateLinkAndButtonLabel = ({
  series,
  module,
  revision,
  everPublished,
  currentUserModuleStatus,
  previousModulesCompleted,
  isPreview,
}: LinkAndButtonLabelProps): {
  buttons: JSX.Element[]
} => {
  const { t } = useTranslation('group')
  const currentUser = useSelector(selectAuthenticatedUser)

  const LABELS = useMemo(() => generateLabels(), [])
  const LINKS = useMemo(
    () => generateLinks(series, revision),
    [series, revision]
  )

  const generateBtnClasses = btnLabel =>
    cs('btn btn--block', { 'btn--fill': btnLabel === LABELS.results })

  // Preview -> Non-functional Start
  if (isPreview) {
    return {
      buttons: [
        <React.Fragment key={generateUUID()}>
          <button
            className={generateBtnClasses(LABELS.start)}
            id={LABELS.start}
          >
            {t(LABELS.start)}
          </button>
        </React.Fragment>,
      ],
    }
  }

  // Unpublished -> No buttons
  if (!everPublished) {
    return { buttons: [<></>] }
  }

  const everCompleted = module.ever_completed

  // Needs subscription or Guest or previous module not completed -> View Details
  if (
    (series.payments_enabled &&
      !series.has_subscription &&
      module.needs_subscription) ||
    (series.force_completion_order &&
      !previousModulesCompleted &&
      !everCompleted) ||
    !currentUser
  ) {
    return {
      buttons: [
        generatePrimaryButton(
          LINKS.start,
          LABELS.details,
          generateBtnClasses,
          t
        ),
      ],
    }
  }

  // In-Progress -> Continue
  if (currentUserModuleStatus === CurrentUserModuleStatus.inProgress) {
    return {
      buttons: [
        generatePrimaryButton(
          LINKS.start,
          LABELS.continue,
          generateBtnClasses,
          t
        ),
      ],
    }
  }

  // Completed - Logic order:
  // MCQ -> Retries Allowed -> Retry
  //     -> Read Only -> View
  //     -> Show Score -> Results
  //     -> Reflect & Rate
  // No MCQ -> Retry (unconditional retrying allowed)
  if (currentUserModuleStatus === CurrentUserModuleStatus.completed) {
    // MCQ
    if (revision.contains_mcq) {
      if (revision.mcq_settings_retry === McqSettingsRetry.Yes) {
        return {
          buttons: [
            generatePrimaryButton(
              LINKS.start,
              LABELS.retry,
              generateBtnClasses,
              t
            ),
          ],
        }
      }

      if (revision.mcq_settings_retry === McqSettingsRetry.ReadOnly) {
        return {
          buttons: [
            generatePrimaryButton(
              LINKS.readOnly,
              LABELS.view,
              generateBtnClasses,
              t
            ),
          ],
        }
      }

      if (revision.mcq_settings_show_score) {
        return {
          buttons: [
            generatePrimaryButton(
              LINKS.results,
              LABELS.results,
              generateBtnClasses,
              t
            ),
          ],
        }
      }

      return {
        buttons: [
          generatePrimaryButton(
            LINKS.reflectAndRate,
            LABELS.reflectAndRate,
            generateBtnClasses,
            t
          ),
        ],
      }
    }

    // No MCQ -> Retry
    return {
      buttons: [
        generatePrimaryButton(LINKS.start, LABELS.retry, generateBtnClasses, t),
      ],
    }
  }

  // Fallback -> Start
  return {
    buttons: [
      generatePrimaryButton(LINKS.start, LABELS.start, generateBtnClasses, t),
    ],
  }
}
